import uuidv4 from "uuid-v4";
import { format } from 'date-fns'
import { isFinite } from 'lodash-es';
import { config } from './config';


export function app_version() {
  if (!process.env.npm_package_version) {
    return "dev";
  } else {
    return process.env.npm_package_version + '@' + (process.env.VERCEL_GIT_COMMIT_SHA).substr(0, 7);
  }
}

export function present_json(json: object): string {
  return JSON.stringify(json, null, 4);
}

export function isEscKey(e): boolean {
  return e.keyCode && e.keyCode === 27;
}

export function all_true(...tests: any | any[]) {
  return tests.every(i => i === true)
}

export function all_false(...tests: any | any[]) {
  return tests.every(i => i === false)
}

export function all_null(...tests: any | any[]) {
  return tests.every(i => i === null);
}

// 
// Presentation
// 

export function precise(val, precision: number = 4) {
  return Number.parseFloat(val).toPrecision(precision);
}

export function truncate_string(string, length = 7, elipsis = true): string {
  if (!string) return '';
  if (string.length <= length) return string;
  const short = string.substr(0, length);
  if (elipsis) return short + '...';
  return short;
}

// Limit numbers to precision, strings/booleans just fine. 
export function present_value(value: number | string | boolean, precision = config.display.number_precision) {

  if (!isFinite(value)) return value.toString();

  const zeroed = "0." + Array(precision - 1).fill(0).join("");
  let present = precise(value, precision);
  if (present === zeroed) {
    present = "0";
  }

  const close_to_zero = Number("0." + Array(precision).fill(0).join("") + "1");
  if (value <= close_to_zero) {
    present = "0";
  }

  return present;
}

// 
// Parcel path
// 

export function generate_new_parcel_path(): string {
  const timestamp = format(new Date(), 'yMMdd_HHmm');
  const uuid_part = uuidv4().slice(0, 8);
  return `${timestamp}_${uuid_part}`;
}

/** Get a filename without extension
 * @param  {} filename
 * @returns string
 */
export function get_file_root(filename): string {
  var i = filename.lastIndexOf('.');
  return (i < 0) ? filename : filename.substr(0, i);
}

export function get_extension(filename): string {
  var i = filename.lastIndexOf('.');
  return (i < 0) ? '' : filename.substr(i + 1);
}


// Without using Math.min, to avoid callstack error
export function array_max_min(array): { min: number, max: number } {
  let min = array[0];
  let max = array[0];

  for (var i = 1; i < array.length; i++) {
    if (array[i] < min) {
      min = array[i];
    }
    if (array[i] > max) {
      max = array[i];
    }

  }
  return { min, max };
}